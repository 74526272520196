<template>
  <div class="ct-home margin-content page-store">
    <div class="ct-home-filter">
      <div class="container">
        <div class="d-flex align-items-center">
          <div class="filter-btn">
            <button
              class="btn btn-info"
              @click="showHideModal"
              name="search_content_button"
            >
              <b-icon class="filter-icon mr-3" icon="search" />絞り込み検索
            </button>
          </div>
        </div>
        <div class="ct-home-allOption d-flex flex-wrap align-items-center mt-4">
          <div
            v-if="moreRecommend"
            class="allOption-item mb-4 mr-4 d-flex align-items-center"
          >
            <div class="allOption-content mr-2">おすすめ</div>
            <div class="allOption-close" @click="removeFilter(index, 1)">
              <font-awesome-icon :icon="['fas', 'times']" />
            </div>
          </div>
          <div v-else class="d-flex flex-wrap">
            <div
              v-for="(value, index) in keywordDisplay"
              class="allOption-item mb-4 mr-4 d-flex align-items-center"
              :key="index"
            >
              <div class="allOption-content mr-2">{{ value }}</div>
              <div class="allOption-close" @click="removeFilter(index, 0)">
                <font-awesome-icon :icon="['fas', 'times']" />
              </div>
            </div>
            <div
              v-for="(value, index) in filterDisplay"
              class="allOption-item mb-4 mr-4 d-flex align-items-center"
              :key="index"
            >
              <div class="allOption-content mr-2">{{ value.text }}</div>
              <div class="allOption-close" @click="removeFilter(index, 1)">
                <font-awesome-icon :icon="['fas', 'times']" />
              </div>
            </div>
          </div>
          <div
            v-for="(value, index) in categoryDisplay"
            class="allOption-item mb-4 mr-4 d-flex align-items-center"
            :key="index"
          >
            <div class="allOption-content mr-2">
              {{ value.text }}
            </div>
            <div class="allOption-close" @click="removeFilter(index, 2)">
              <font-awesome-icon :icon="['fas', 'times']" />
            </div>
          </div>
          <div
            v-for="(value, index) in authorDisplay"
            class="allOption-item mb-4 mr-4 d-flex align-items-center"
            :key="index"
          >
            <div class="allOption-content mr-2">{{ value.text }}</div>
            <div class="allOption-close" @click="removeFilter(index, 3)">
              <font-awesome-icon :icon="['fas', 'times']" />
            </div>
          </div>
        </div>
        <div v-if="isOpenFilter" class="ct-home-modalFilter">
          <div
            class="position-fixed modalFilter-backdrop"
            @click="showHideModal"
          />
          <div class="position-fixed modalFilter-content">
            <div
              v-if="modalOpen > 1"
              class="modalFilter-return"
              @click="returnModal"
            >
              <b-icon icon="arrow-left" scale="1" />
            </div>
            <div class="modalFilter-close" @click="showHideModal">
              <font-awesome-icon :icon="['fas', 'times']" />
            </div>
            <div class="modalFilter-header text-center">{{ modalTitle }}</div>
            <div style="max-height: 70vh; overflow: auto">
              <div
                v-for="(value, index) in modalContent"
                class="modalFilter-item position-relative"
                :key="index"
                @click="chooseFilter(value)"
              >
                <div>{{ value.text }}</div>
                <div
                  class="modalFilter-itemIcon position-absolute"
                  v-bind:class="{ 'modalFilter-check': !value.open }"
                >
                  <font-awesome-icon
                    v-if="value.open"
                    icon="fa-solid fa-angle-right"
                  />
                  <b-icon
                    v-else-if="
                      (modalOpen === 1 &&
                        filterSelect.find((item) => item.id === value.id)) ||
                      (modalOpen === 2 &&
                        categorySelect.find((item) => item.id === value.id)) ||
                      (modalOpen === 3 &&
                        authorSelect.find((item) => item.id === value.id))
                    "
                    icon="check"
                    scale="2"
                  />
                </div>
              </div>
              <div v-if="modalOpen === 1" class="modalFilter-search mt-3">
                <label>キーワード</label>
                <div class="ct-home-search d-flex align-items-center">
                  <b-form-input
                    class="search-input"
                    v-model="formdata.keyword"
                    placeholder="商品名・目次で検索・著者名・カテゴリ名"
                  />
                  <b-icon class="search-icon" icon="search" />
                </div>
              </div>
              <div
                v-if="
                  modalOpen === 2 &&
                  listCategory.current_page < listCategory.last_page
                "
                class="d-flex justify-content-center align-items-center pb-5"
              >
                <b-button class="btn-info" @click="seeMoreCategory"
                  >一覧で表示する</b-button
                >
              </div>
              <div
                v-if="
                  modalOpen === 3 &&
                  listAuthor.current_page < listAuthor.last_page
                "
                class="d-flex justify-content-center align-items-center pb-5"
              >
                <b-button class="btn-info" @click="seeMoreAuthor"
                  >一覧で表示する</b-button
                >
              </div>
              <div class="pb-4 modalFilter-btn d-flex justify-content-center">
                <b-button
                  class="btn-info mt-3"
                  @click="searchStore"
                  :disabled="loadSearch"
                >
                  <b-spinner v-if="loadSearch" small></b-spinner>
                  <span>検索する</span></b-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isDisplayRecommend" class="container mt-3">
      {{ messageEmptyContent }}
    </div>
    <div v-if="!isDisplayRecommend">
      <div class="ct-home-content category-list px-3">
        <div class="container">
          <!--<h4
            v-if="
              userProfile &&
              userProfile.first_name &&
              userProfile.last_name &&
              listDataStore.length > 0
            "
            class="font-title"
          >
            {{ userProfile.last_name + userProfile.first_name }}さんへのおすすめ2
          </h4>-->
          <h1 class="category-name">{{ category_name }}</h1>
          <b-row>
            <b-col
              v-for="(value, index) in listDataStore"
              class="col-6"
              md="4"
              lg="4"
              :key="index"
              style="padding: 0 2%; margin-bottom: 4%"
            >
              <div
                @click="goToDetailContent(value)"
                class="ct-home-card content-filter"
                style="border: unset !important"
              >
                <div
                  class="card-img"
                  :class="{
                    'd-flex align-items-center justify-content-center':
                      !value.image_url,
                  }"
                >
                  <img
                    draggable="false"
                    v-if="value.image_url"
                    :src="`${urlBackend}/storage/${value.image_url}`"
                    alt=""
                  />
                  <div class="h6 text-dark title-store" v-else>
                    <span
                      style="
                        text-align: center;
                        margin: 0;
                        white-space: normal;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                      "
                    >
                      {{ value.title }}
                    </span>
                  </div>
                </div>
                <div class="card-name">
                  {{ value.title }}
                </div>
                <div
                  class="d-flex align-items-center px-3"
                  @click="goToDetailAuthor(value.author_id)"
                  v-on:click.stop
                >
                  <div class="card-avatar">
                    <img
                      draggable="false"
                      :src="
                        value.avatar
                          ? `${urlBackend}/${value.avatar}`
                          : image_url_default
                      "
                      alt=""
                    />
                  </div>
                  <div class="card-nickName ml-2 text-dark">
                    {{ value.last_name + " " + value.first_name }}
                  </div>
                </div>
                <!-- <div
                  class="d-flex justify-content-between align-items-center card-desc-pc"
                >
                  <div
                    class="card-price"
                    v-if="
                      value.sale_basic.column &&
                      (value.sale_basic.column[0].product_price > 0 ||
                        value.sale_basic.column[0].fee > 0)
                    "
                  >
                    ￥{{
                      value.sale_basic.column[0].fee
                        ? parseInt(value.sale_basic.column[0].product_price) +
                          parseInt(value.sale_basic.column[0].fee)
                        : parseInt(value.sale_basic.column[0].product_price)
                    }}
                  </div>
                  <div class="card-name px-3 pt-3 pb-3" v-else>無料</div>
                  <div
                    class="card-btn"
                    v-if="
                      value.sale_basic.column &&
                      value.sale_basic.column.length > 0 &&
                      (value.sale_basic.column[0].product_price != 0 ||
                        value.sale_basic.column[0].fee != 0)
                    "
                  >
                    <b-button
                      style="
                        font-size: 0.75rem;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                      "
                      class="btn-secondary p-1"
                      v-if="check(value.id)"
                      disabled
                      v-on:click.stop
                      >カートに追加済み</b-button
                    >
                    <button
                      :name="`add_content_` + `${value.id}`"
                      class="btn-success btn btn-add-cart p-1"
                      v-else
                      @click="addCart(value)"
                      :disabled="checkloading(value.id)"
                      v-on:click.stop
                      style="font-size: 0.75rem"
                    >
                      <b-spinner
                        v-if="checkloading(value.id)"
                        small
                      ></b-spinner>
                      カートに入れる
                    </button>
                  </div>
                  <div v-else class="card-btn">
                    <span
                      class="text"
                      style="
                        font-size: 0.75rem;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                      "
                      >カート不可の商品です</span
                    >
                  </div>
                </div> 
                <div class="card-desc-sp"> -->
                <div class="card-desc">
                  <p
                    v-if="
                      value.sale_basic.column &&
                      (value.sale_basic.column[0].product_price > 0 ||
                        value.sale_basic.column[0].fee > 0)
                    "
                  >
                    ￥{{
                      value.sale_basic.column[0].fee
                        ? parseInt(value.sale_basic.column[0].product_price) +
                          parseInt(value.sale_basic.column[0].fee)
                        : parseInt(value.sale_basic.column[0].product_price)
                    }}
                  </p>
                  <p v-else>無料</p>
                </div>
              </div>
            </b-col>
          </b-row>
          <!-- <div
            v-for="(value, index) in listDataStore"
            :key="index"
            
            class="version-sp"
          >
            <div class="content-cart-sp">
              <div class="d-flex info">
                <div class="image">
                  <img
                    v-if="value.image_url"
                    :src="`${urlBackend}/storage/${value.image_url}`"
                    alt=""
                  />
                  <div class="h5 text-dark title-store px-4" v-else>
                    {{ value.title }}
                  </div>
                </div>
                <div class="info-content">
                  <div class="card-name pl-3 pt-1 pb-1 font-title-content">
                    {{ value.title }}
                  </div>
                  <div
                    class="card-name px-3 pt-3 pb-3"
                    
                    v-if="
                      value.sale_basic.column &&
                      (value.sale_basic.column[0].product_price > 0 ||
                        value.sale_basic.column[0].fee > 0)
                    "
                  >
                    ￥{{
                      value.sale_basic.column[0].fee
                        ? parseInt(value.sale_basic.column[0].product_price) +
                          parseInt(value.sale_basic.column[0].fee)
                        : parseInt(value.sale_basic.column[0].product_price)
                    }}
                  </div>
                  <div class="card-name px-3 pt-3 pb-3" v-else>無料</div>
                  <div class="d-flex align-items-center pb-1 pl-3 author">
                    <div class="card-avatar">
                      <img
                        :src="
                          value.avatar
                            ? `${urlBackend}/${value.avatar}`
                            : image_url_default
                        "
                        alt=""
                      />
                    </div>
                    <div class="card-nickName ml-2 text-dark">
                      {{ value.last_name + " " + value.first_name }}
                    </div>
                  </div>
                  <div class="card-btn pb-3 pl-3">
                    <b-button
                      class="btn-secondary btn-width"
                      style="min-width: 140px"
                      v-if="check(value.id)"
                      disabled
                      v-on:click.stop
                      >カートに追加済み</b-button
                    >
                    <b-button
                      v-else
                      class="mt-2 btn-success"
                      style="min-width: 140px"
                      @click="addCart(value)"
                      :disabled="checkloading(value.id)"
                      v-on:click.stop
                      ><b-spinner
                        v-if="checkloading(value.id)"
                        small
                      ></b-spinner>
                      <span> カートに入れる</span></b-button
                    >
                    <b-button
                      class="mt-2 btn-info"
                      style="min-width: 140px"
                      @click="goToDetailContent(value)"
                      v-on:click.stop
                      >詳細</b-button
                    >
                  </div>
                </div>
                <div
                  class="card-name px-3 pt-3 pb-3"
                  
                  v-if="
                    value.sale_basic.column &&
                    (value.sale_basic.column[0].product_price > 0 ||
                      value.sale_basic.column[0].fee > 0)
                  "
                >
                  ￥{{
                    formatNumber(
                      value.sale_basic.column[0].product_price * 1 +
                        value.sale_basic.column[0].fee * 1
                    )
                  }}
                </div>
                <div class="card-name px-3 pt-3 pb-3" v-else>無料</div>
                <div
                  class="card-btn"
                  
                  v-if="
                    value.sale_basic.column &&
                    value.sale_basic.column.length > 0 &&
                    (value.sale_basic.column[0].product_price != 0 ||
                      value.sale_basic.column[0].fee != 0)
                  "
                >
                  <b-button
                    class="btn-secondary"
                    v-if="value.is_added_cart"
                    disabled
                    v-on:click.stop
                  >
                    カートに追加済み
                  </b-button>
                  <button
                    :name="`add_content_` + `${value.id}`"
                    class="btn-success btn"
                    v-else
                    @click="addCart(value)"
                    v-on:click.stop
                  >
                    カートに入れる
                  </button>
                </div>
                <div v-else class="card-btn pb-3">
                  <span class="text">カート不可の商品です</span>
                </div>
              </div>
            </div>
          </div> -->
        </div>
        <div
          class="see-more"
          v-if="dataStore.current_page < dataStore.last_page"
        >
          <b-button class="btn-info btn--go-list" @click="seeMore"
            >続きを見る</b-button
          >
        </div>
        <div class="container mt-3">{{ messageEmpty }}</div>
      </div>
    </div>
    <div v-else-if="dataRecommend">
      <template v-for="(value, index) in dataRecommend.recommend">
        <div
          :key="index"
          :class="
            (settingWidget == 1 && index % 4 === 1) ||
            (settingWidget == 0 && optionWidget == 2)
              ? 'ct-home-content  ct-home-content-2'
              : 'ct-home-content'
          "
        >
          <h4
            class="font-title d-flex justify-content-center"
            :class="
              settingWidget == 0
                ? 'widget' + optionWidget + '-title'
                : index % 4 === 0
                ? 'widget1-title'
                : index % 4 === 1
                ? 'widget2-title'
                : index % 4 === 2
                ? 'widget3-title'
                : index % 4 === 3
                ? 'widget4-title'
                : ''
            "
            v-if="
              value.category && value.category.category_name === 'recommend'
            "
          >
            <span
              v-if="
                userProfile && userProfile.first_name && userProfile.last_name
              "
              >{{
                userProfile.last_name + userProfile.first_name
              }}さんへのおすすめ</span
            >
          </h4>
          <h4
            class="font-title d-flex justify-content-center"
            :class="
              settingWidget == 0
                ? 'widget' + optionWidget + '-title'
                : index % 4 === 0
                ? 'widget1-title'
                : index % 4 === 1
                ? 'widget2-title'
                : index % 4 === 2
                ? 'widget3-title'
                : index % 4 === 3
                ? 'widget4-title'
                : ''
            "
            v-else
          >
            {{ value.category.category_name }}
          </h4>
          <div
            class="container"
            v-if="value.category"
            :class="
              settingWidget == 0
                ? 'widget' + optionWidget
                : index % 4 === 0
                ? 'widget1'
                : index % 4 === 1
                ? 'widget2 cus-width-ct'
                : index % 4 === 2
                ? 'widget3'
                : index % 4 === 3
                ? 'widget4'
                : ''
            "
          >
            <div
              v-if="
                (settingWidget == 1 && index % 4 === 2) ||
                (settingWidget == 0 && optionWidget == 3)
              "
            >
              <div
                v-for="(valueContent, indexContent) in value.data"
                :key="indexContent"
              >
                <div
                  v-if="indexContent === 0 || indexContent === 2"
                  class="d-flex justify-content-around"
                  :class="indexContent == 0 ? 'mb-5' : ''"
                >
                  <template
                    v-for="(valueContentRow, indexContentRow) in value.data"
                  >
                    <div
                      :key="indexContentRow"
                      :style="
                        (indexContent === 0 && indexContentRow < 2) ||
                        (indexContent === 2 && indexContentRow > 1)
                          ? { display: 'block' }
                          : { display: 'none' }
                      "
                      v-if="indexContentRow < 4"
                    >
                      <div>
                        <div
                          class="ct-home-card widget2"
                          style="left: 0"
                          @click="goToDetailContent(valueContentRow)"
                        >
                          <div
                            class="card-img img-widget"
                            :class="{
                              'd-flex  align-items-center justify-content-center no-img':
                                !valueContentRow.image_url,
                            }"
                            style="margin: 0 auto"
                          >
                            <img
                              draggable="false"
                              v-if="valueContentRow.image_url"
                              :src="`${urlBackend}/storage/${valueContentRow.image_url}`"
                              alt=""
                            />
                            <div class="h5 text-dark title-store px-4" v-else>
                              {{ valueContentRow.title }}
                            </div>
                          </div>
                          <div class="title-content-widget">
                            <div
                              class="card-name px-3 font-title-content title-widget"
                            >
                              {{ valueContentRow.title }}
                            </div>
                          </div>
                          <div
                            class="d-flex align-items-center px-3"
                            @click="goToDetailAuthor(valueContentRow.author_id)"
                            v-on:click.stop
                          >
                            <div class="card-avatar">
                              <img
                                draggable="false"
                                :src="
                                  valueContentRow.avatar
                                    ? `${urlBackend}/${valueContentRow.avatar}`
                                    : image_url_default
                                "
                                alt=""
                              />
                            </div>
                            <div class="card-nickName ml-2 text-dark">
                              {{
                                valueContentRow.last_name +
                                " " +
                                valueContentRow.first_name
                              }}
                            </div>
                          </div>
                          <div
                            class="card-name px-3 pt-3 pb-3"
                            v-if="
                              valueContentRow.sale_basic.column &&
                              valueContentRow.sale_basic.column.length > 0 &&
                              (valueContentRow.sale_basic.column[0]
                                .product_price != 0 ||
                                valueContentRow.sale_basic.column[0].fee != 0)
                            "
                          >
                            ￥{{
                              valueContentRow.sale_basic.column[0].fee
                                ? parseInt(
                                    valueContentRow.sale_basic.column[0]
                                      .product_price
                                  ) +
                                  parseInt(
                                    valueContentRow.sale_basic.column[0].fee
                                  )
                                : parseInt(
                                    valueContentRow.sale_basic.column[0]
                                      .product_price
                                  )
                            }}
                          </div>
                          <div class="card-name px-3 pt-3 pb-3" v-else>
                            無料
                          </div>
                          <div
                            class="card-btn pb-3"
                            v-if="
                              valueContentRow.sale_basic.column &&
                              valueContentRow.sale_basic.column.length > 0 &&
                              (valueContentRow.sale_basic.column[0]
                                .product_price != 0 ||
                                valueContentRow.sale_basic.column[0].fee != 0)
                            "
                          >
                            <b-button
                              class="btn-secondary btn-width"
                              v-if="check(valueContentRow.id)"
                              disabled
                              v-on:click.stop
                            >
                              カートに追加済み
                            </b-button>
                            <button
                              :name="`add_content_` + `${valueContentRow.id}`"
                              class="btn-success btn-width btn"
                              v-else
                              @click="addCart(valueContentRow)"
                              :disabled="checkloading(valueContentRow.id)"
                              v-on:click.stop
                            >
                              <b-spinner
                                v-if="checkloading(valueContentRow.id)"
                                small
                              ></b-spinner>
                              <span> カートに入れる</span>
                            </button>
                          </div>
                          <div v-else class="card-btn pb-3">
                            <span class="text">カート不可の商品です</span>
                          </div>
                          <div class="card-btn pb-3">
                            <button
                              class="btn-info btn-width btn"
                              @click="goToDetailContent(valueContentRow)"
                              v-on:click.stop
                            >
                              詳細
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <!-- <div class="d-flex justify-content-center carousel-widget"> -->
            <div
              v-if="
                (settingWidget == 1 && index % 4 === 1) ||
                (settingWidget == 0 && optionWidget == 2)
              "
              class="d-flex justify-content-center"
            >
              <b-col class="">
                <div class="header-listOption-menu-2">
                  <VueSlickCarousel
                    :swipeToSlide="true"
                    v-bind="
                      settingWidget == 0 && optionWidget == 2
                        ? settingsSlickWidget2[index]
                        : settingsSlickWidget2[Math.floor(index / 4)]
                    "
                    :responsive="responsiveSettings"
                    v-if="value.data.length > 0"
                    class="car-slide"
                    :ref="createRef(index)"
                  >
                    <template #nextArrow="arrowOption">
                      <div @click="arrowOption.onClick" class="cus-icon-n">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="42"
                          height="42"
                          fill="currentColor"
                          class="bi bi-chevron-right"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                          />
                        </svg>
                        {{ arrowOption.currentSlide + 4 }}/{{
                          arrowOption.slideCount
                        }}
                      </div>
                    </template>
                    <template #prevArrow="arrowOption">
                      <div @click="arrowOption.onClick" class="cus-icon-n">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="42"
                          height="42"
                          fill="currentColor"
                          class="bi bi-chevron-left"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                          />
                        </svg>
                        {{ arrowOption.currentSlide }}/{{
                          arrowOption.slideCount
                        }}
                      </div>
                    </template>
                    <div
                      v-for="(valueContent, indexContent) in value.data"
                      :key="indexContent"
                      style="width: unset"
                    >
                      <div class="width_st">
                        <div class="ct-home-card-2">
                          <div
                            class="card-img-widget-2"
                            :class="{
                              'd-flex  align-items-center justify-content-center no-img':
                                !valueContent.image_url,
                            }"
                            @click="goToDetailContent(valueContent)"
                          >
                            <img
                              draggable="false"
                              width="300"
                              height="300"
                              v-if="valueContent.image_url"
                              :src="`${urlBackend}/storage/${valueContent.image_url}`"
                              alt=""
                            />
                            <div class="h5 text-dark title-store px-4" v-else>
                              {{ valueContent.title }}
                            </div>
                          </div>
                          <div class="info-content-2">
                            <div class="title-content-widget">
                              <div class="px-3 font-title-content-wg2">
                                {{ valueContent.title }}
                              </div>
                            </div>
                            <div
                              class="d-flex align-items-center px-3"
                              @click="goToDetailAuthor(valueContent.author_id)"
                              v-on:click.stop
                            >
                              <div class="card-avatar">
                                <img
                                  draggable="false"
                                  width="300"
                                  height="300"
                                  :src="
                                    valueContent.avatar
                                      ? `${urlBackend}/${valueContent.avatar}`
                                      : image_url_default
                                  "
                                  alt=""
                                />
                              </div>
                              <div class="card-nickName ml-2 text-dark">
                                {{
                                  valueContent.last_name +
                                  " " +
                                  valueContent.first_name
                                }}
                              </div>
                            </div>
                            <div
                              class="wg-2-pr"
                              v-if="
                                valueContent.sale_basic.column &&
                                valueContent.sale_basic.column.length > 0 &&
                                (valueContent.sale_basic.column[0]
                                  .product_price != 0 ||
                                  valueContent.sale_basic.column[0].fee != 0)
                              "
                            >
                              ￥{{
                                valueContent.sale_basic.column[0].fee
                                  ? parseInt(
                                      valueContent.sale_basic.column[0]
                                        .product_price
                                    ) +
                                    parseInt(
                                      valueContent.sale_basic.column[0].fee
                                    )
                                  : parseInt(
                                      valueContent.sale_basic.column[0]
                                        .product_price
                                    )
                              }}
                            </div>
                            <div class="wg-2-pr" v-else>無料</div>
                          </div>
                        </div>
                      </div>
                    </div></VueSlickCarousel
                  >
                </div>
              </b-col>
            </div>
            <div
              v-if="
                (settingWidget == 1 && (index % 4 === 0 || index % 4 === 3)) ||
                (settingWidget == 0 && (optionWidget == 4 || optionWidget == 1))
              "
              class="d-flex justify-content-center"
              :class="
                (settingWidget == 1 && index % 4 === 3) ||
                (settingWidget == 0 && optionWidget == 4)
                  ? 'carousel-widget'
                  : ''
              "
            >
              <b-col class="">
                <div class="header-listOption-menu">
                  <VueSlickCarousel
                    v-bind="
                      (settingWidget == 1 && index % 4 === 3) ||
                      (settingWidget == 0 && optionWidget == 4)
                        ? settingsSlickWidget
                        : settingsSlick
                    "
                    v-if="value.data.length > 0"
                  >
                    <template #nextArrow="arrowOption">
                      <div class="custom-arrow">
                        {{ arrowOption.currentSlide }}/{{
                          arrowOption.slideCount
                        }}
                      </div>
                    </template>
                    <template #prevArrow="arrowOption">
                      <div class="custom-arrow">
                        {{ arrowOption.currentSlide }}/{{
                          arrowOption.slideCount
                        }}
                      </div>
                    </template>
                    <!-- <div
                    class="header-listOption-menu-item-content link-header position-relative d-flex content-widget"
                    v-for="(valueContent, indexContent) in value.data"
                    :key="indexContent"
                  > -->
                    <template
                      v-for="(valueContent, indexContent) in value.data"
                    >
                      <div
                        :key="indexContent"
                        v-if="
                          (settingWidget == 1 && index % 4 !== 3) ||
                          (settingWidget == 0 && optionWidget != 4) ||
                          (settingWidget == 1 &&
                            index % 4 === 3 &&
                            indexContent < 40) ||
                          (settingWidget == 0 &&
                            optionWidget == 4 &&
                            indexContent < 4)
                        "
                        :class="
                          (settingWidget == 1 && index % 4 === 3) ||
                          (settingWidget == 0 && optionWidget == 4)
                            ? 'content-widget'
                            : ''
                        "
                      >
                        <div class="width_setting">
                          <!-- <div class="ct-home-card widget2"> -->
                          <div
                            class="ct-home-card"
                            :class="
                              settingWidget == 0
                                ? 'widget' + optionWidget
                                : index % 4 === 0
                                ? 'widget1'
                                : index % 4 === 2
                                ? 'widget3'
                                : index % 4 === 3
                                ? 'widget4'
                                : ''
                            "
                          >
                            <div
                              class="card-img-widget"
                              :class="{
                                'd-flex  align-items-center justify-content-center no-img':
                                  !valueContent.image_url,
                              }"
                              @click="goToDetailContent(valueContent)"
                            >
                              <img
                                draggable="false"
                                v-if="valueContent.image_url"
                                :src="`${urlBackend}/storage/${valueContent.image_url}`"
                                alt=""
                                @click="goToDetailContent(valueContent)"
                              />
                              <div class="h5 text-dark title-store px-4" v-else>
                                {{ valueContent.title }}
                              </div>
                            </div>
                            <div class="info-content">
                              <div class="title-content-widget">
                                <div
                                  class="card-name px-3 font-title-content"
                                  @click="goToDetailContent(valueContent)"
                                >
                                  {{ valueContent.title }}
                                </div>
                              </div>
                              <div
                                class="d-flex align-items-center px-3"
                                @click="
                                  goToDetailAuthor(valueContent.author_id)
                                "
                                v-on:click.stop
                              >
                                <div class="card-avatar">
                                  <img
                                    draggable="false"
                                    :src="
                                      valueContent.avatar
                                        ? `${urlBackend}/${valueContent.avatar}`
                                        : image_url_default
                                    "
                                    alt=""
                                  />
                                </div>
                                <div class="card-nickName ml-2 text-dark">
                                  {{
                                    valueContent.last_name +
                                    " " +
                                    valueContent.first_name
                                  }}
                                </div>
                              </div>
                              <div
                                class="card-name card-price px-3 pt-3 pb-3"
                                v-if="
                                  valueContent.sale_basic.column &&
                                  valueContent.sale_basic.column.length > 0 &&
                                  (valueContent.sale_basic.column[0]
                                    .product_price != 0 ||
                                    valueContent.sale_basic.column[0].fee != 0)
                                "
                              >
                                ￥{{
                                  valueContent.sale_basic.column[0].fee
                                    ? parseInt(
                                        valueContent.sale_basic.column[0]
                                          .product_price
                                      ) +
                                      parseInt(
                                        valueContent.sale_basic.column[0].fee
                                      )
                                    : parseInt(
                                        valueContent.sale_basic.column[0]
                                          .product_price
                                      )
                                }}
                              </div>
                              <div class="card-name px-3 pt-3 pb-3" v-else>
                                無料
                              </div>
                              <div
                                class="card-btn pb-3"
                                v-if="
                                  valueContent.sale_basic.column &&
                                  valueContent.sale_basic.column.length > 0 &&
                                  (valueContent.sale_basic.column[0]
                                    .product_price != 0 ||
                                    valueContent.sale_basic.column[0].fee != 0)
                                "
                              >
                                <b-button
                                  class="btn-secondary btn-width"
                                  v-if="check(valueContent.id)"
                                  disabled
                                  v-on:click.stop
                                >
                                  カートに追加済み
                                </b-button>
                                <button
                                  :name="`add_content_` + `${valueContent.id}`"
                                  class="btn-success btn-width btn"
                                  v-else
                                  @click="addCart(valueContent)"
                                  :disabled="checkloading(valueContent.id)"
                                  v-on:click.stop
                                >
                                  <b-spinner
                                    v-if="checkloading(valueContent.id)"
                                    small
                                  ></b-spinner>
                                  <span>カートに入れる</span>
                                </button>
                              </div>
                              <div v-else class="card-btn pb-3">
                                <span class="text">カート不可の商品です</span>
                              </div>
                              <div class="card-btn pb-3">
                                <button
                                  class="btn-info btn-width btn"
                                  @click="goToDetailContent(valueContent)"
                                  v-on:click.stop
                                >
                                  詳細
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template></VueSlickCarousel
                  >
                </div>
              </b-col>
            </div>
            <div
              class="d-flex justify-content-center align-items-center"
              style="padding: 5.8% 0"
            >
              <button
                class="btn-info btn btn--go-list"
                @click="searchList(value)"
                v-if="value.isOverLength"
              >
                一覧で表示する
              </button>
            </div>
          </div>
          <div
            v-if="
              dataRecommend.advertising_frequency &&
              (index + 1) % dataRecommend.advertising_frequency === 0 &&
              dataRecommend.json_url.length > 0
            "
            class="image-advertising py-5"
          >
            <a
              draggable="false"
              :href="
                (((index + 1) / dataRecommend.advertising_frequency) %
                  dataRecommend.json_url.length) -
                  1 >=
                0
                  ? dataRecommend.json_url[
                      (((index + 1) / dataRecommend.advertising_frequency) %
                        dataRecommend.json_url.length) -
                        1
                    ].url
                  : dataRecommend.json_url[dataRecommend.json_url.length - 1]
                      .url
              "
              target="blank"
            >
              <img
                :src="
                  (((index + 1) / dataRecommend.advertising_frequency) %
                    dataRecommend.json_url.length) -
                    1 >=
                  0
                    ? `${urlBackend}/${
                        dataRecommend.json_url[
                          (((index + 1) / dataRecommend.advertising_frequency) %
                            dataRecommend.json_url.length) -
                            1
                        ].url_image
                      }`
                    : `${urlBackend}/${
                        dataRecommend.json_url[
                          dataRecommend.json_url.length - 1
                        ].url_image
                      }`
                "
              />
            </a>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import image_url_default from "@/assets/img/content-default.png";
import { mapActions, mapGetters } from "vuex";
import { Constants } from "../../utils/constants";
import moment from "moment";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
export default {
  name: "Home",
  components: { VueSlickCarousel },
  data() {
    return {
      urlBackend: Constants.URL_BE,
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      info: JSON.parse(localStorage.getItem(Constants.NORMAL_USER_INFO)),
      user_type: JSON.parse(localStorage.getItem(Constants.USER_TYPE_USER)),
      name: localStorage.getItem(Constants.NORMAL_USER_INFO)
        ? JSON.parse(localStorage.getItem(Constants.NORMAL_USER_INFO))
            .last_name +
          " " +
          JSON.parse(localStorage.getItem(Constants.NORMAL_USER_INFO))
            .first_name
        : "",
      image_url_default,
      text: "",
      isOpenFilter: false,
      modalOpen: 1,
      modalTitle: "絞り込み検索",
      modalContent: [],
      modalContent1: [
        {
          text: "カテゴリ検索",
          open: true,
          openId: 2,
        },
        {
          text: "著者名",
          open: true,
          openId: 3,
        },
      ],
      modalContent2: [],
      modalContent3: [],
      keywordSelect: [],
      filterSelect: [],
      authorSelect: [],
      categorySelect: [],
      keywordDisplay: [],
      filterDisplay: [],
      authorDisplay: [],
      categoryDisplay: [],
      formdata: {
        keyword: "",
        author_id: [],
        category_id: [],
        shop_id: localStorage.getItem(Constants.SHOP_ID),
        content_type: [],
        limit: 6,
        page: 1,
      },
      moreRecommend: false,
      idContentChangeCart: null,
      isSeeMore: true,
      listDataStore: [],
      messageEmpty: "",
      messageEmptyContent: "",
      formRecommend: {
        shop_id: localStorage.getItem(Constants.SHOP_ID),
      },
      isDisplayRecommend: true,
      listCategoryStore: [],
      listAuthorStore: [],
      formCategory: {
        shop_id: localStorage.getItem(Constants.SHOP_ID),
        page: 1,
      },
      formAuthor: {
        shop_id: localStorage.getItem(Constants.SHOP_ID),
        page: 1,
      },
      settingsSlick: {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: true,
        variableWidth: true,
        swipeToSlide: true,
        // centerMode: true,
      },
      settingsSlickWidget2: [],
      settingsSlickWidget: {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: true,
        swipeToSlide: true,
      },
      isPreSale: false,
      saleMethod: Constants.CONTENT_TYPE,
      listDataRecommendTemp: [],
      listWidget: [],
      checkData: null,
      loadSearch: false,
      isDataAuthor: false,
      isDataCategory: false,
      responsiveSettings: [
        {
          breakpoint: 950,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 676,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 450,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            infinite: true,
            centerPadding: "25%",
          },
        },
        {
          breakpoint: 405,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            infinite: true,
            centerPadding: "22%",
          },
        },
        {
          breakpoint: 355,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            infinite: true,
            centerPadding: "18%",
          },
        },
      ],
      settingWidget: 1,
      optionWidget: 1,
      category_name: "",
    };
  },
  computed: {
    ...mapGetters({
      dataStore: "dataStore",
      success: "success",
      error: "error",
      message: "message",
      listAuthor: "listAuthor",
      listCategory: "listCategory",
      dataRecommend: "dataRecommend",
      userProfile: "userProfile",
      listCart: "listCart",
    }),
  },
  beforeDestroy(to, from, next) {
    this.$store.commit("set", ["dataRecommend", []]);
    next();
  },
  watch: {
    // $route: {
    //   immediate: true,
    //   handler() {
    //     console.log(this.$route.query.keyword, "this.$route.query.keyword");
    //     if (this.$route.query.keyword) {
    //       this.formdata.keyword = this.$route.query.keyword;
    //       this.keywordDisplay.push(this.formdata.keyword);
    //       this.isDisplayRecommend = false;
    //       this.getDataStore(this.formdata);
    //     } else {
    //       this.getListRecommendInStore(this.formRecommend);
    //     }
    //     this.getListAuthorInStore(this.formAuthor);
    //     this.getListCategoryInStore(this.formCategory);
    //   },
    // },
    dataRecommend: {
      handler() {
        this.messageEmptyContent = null;
        if (this.dataRecommend && Object.keys(this.dataRecommend).length > 0) {
          this.settingWidget = this.dataRecommend.setting_widget;
          this.optionWidget = this.dataRecommend.option_widget;
          let baseDefault = this.$root.$refs.baseDefault;
          let count = 0;
          const self = this;
          if (this.dataRecommend.recommend) {
            this.dataRecommend.recommend.forEach((element, index) => {
              if (element.data && element.data.length > 0) {
                if (count === 4) {
                  count = 1;
                } else {
                  count += 1;
                }
                if (!this.settingWidget && this.optionWidget == 2) {
                  this.settingsSlickWidget2.push({
                    dots: false,
                    infinite: false,
                    speed: 700,
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    arrows: true,
                    centerMode: element.data.length < 4 ? true : false,
                  });
                } else if (index % 4 == 1) {
                  this.settingsSlickWidget2.push({
                    dots: false,
                    infinite: false,
                    speed: 700,
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    arrows: true,
                    centerMode: element.data.length < 4 ? true : false,
                  });
                }
                self.listWidget.push(count);
                element.data.forEach((item) => {
                  // change sentence
                  item.title = baseDefault.changeSentence(item.title);
                  if (
                    item.sale_basic &&
                    parseInt(item.sale_basic.column[0].priceOverTime) > 0 &&
                    item.sale_basic.column[0].fromTimeRelease <=
                      moment(String(new Date())).format("YYYY-MM-DD HH:mm") &&
                    item.sale_basic.column[0].toTimeRelease >=
                      moment(String(new Date())).format("YYYY-MM-DD HH:mm")
                  ) {
                    item.sale_basic.column[0].product_price = parseInt(
                      item.sale_basic.column[0].priceOverTime
                    );
                  }
                  if (
                    item.sale_other &&
                    item.sale_other.sale_method === this.saleMethod.pre_sale &&
                    item.sale_other.pre_sale_start_date <=
                      moment(String(new Date())).format(
                        "YYYY-MM-DD HH:mm:ss"
                      ) &&
                    item.sale_other.pre_sale_end_date >=
                      moment(String(new Date())).format("YYYY-MM-DD HH:mm:ss")
                  ) {
                    item.product_price = item.sale_other.pre_sale_price;
                  }
                  if (
                    item.sale_basic.column &&
                    item.sale_basic.column.length > 1
                  ) {
                    item["isMultiPlan"] = false;
                  }
                });
              }
            });
            // this.dataRecommend.recommend[1].data =
            //   this.dataRecommend.recommend[1].data.concat(
            //     this.dataRecommend.recommend[1].data
            //   );
            // this.dataRecommend.recommend[1].data =
            //   this.dataRecommend.recommend[1].data.concat(
            //     this.dataRecommend.recommend[1].data[0]
            //   );
            if (
              this.settingWidget == 1 ||
              (this.settingWidget == 0 && this.optionWidget == 2)
            ) {
              const selft = this;
              setTimeout(function () {
                selft.onWheel();
              }, 50);
            }
          }
        } else {
          this.messageEmptyContent = "ショップにコンテンツがありません。";
        }
      },
      deep: true,
    },
    dataStore: {
      handler() {
        this.messageEmptyContent = "";
        this.messageEmpty = "";
        if (this.dataStore && this.dataStore.data.length > 0) {
          let baseDefault = this.$root.$refs.baseDefault;
          if (this.isSeeMore) {
            this.dataStore.data.forEach((element) => {
              this.listDataStore.push(element);
            });
          } else {
            this.listDataStore = this.dataStore.data;
          }
          // change title content
          this.listDataStore.forEach(function (item) {
            item.title = baseDefault.changeSentence(item.title);
          });
          this.messageEmpty = "";
        } else {
          if (!this.isSeeMore) {
            this.listDataStore = [];
            this.messageEmpty = "該当するコンテンツは見つかりませんでした。";
            this.messageEmptyContent = "ショップにコンテンツがありません。";
          }
        }
      },
      deep: true,
    },
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["success", false]);
        this.$store.commit("set", ["message", ""]);
        this.getListCart({
          shop_id: this.shop_id,
        });
        this.getListRecommendInStore(this.formRecommend);
        let listContent = this.listDataStore.filter(
          (item) => item.id === this.idContentChangeCart
        )[0];
        if (listContent) {
          listContent.is_added_cart = true;
        }
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
        this.$store.commit("set", ["modalLoadingPage", false]);
      }
    },
    listAuthor() {
      this.isDataAuthor = true;
      if (this.isDataAuthor && this.isDataCategory) {
        this.$store.commit("set", ["modalLoadingPage", false]);
      }
      if (this.listAuthor.data.length > 0) {
        this.listAuthorStore = this.listAuthor.data.map((item) => ({
          text: item.last_name + " " + item.first_name,
          open: false,
          modalId: 3,
          id: item.user_id,
        }));
        this.listAuthorStore.forEach((element) => {
          if (
            JSON.stringify(this.listAuthorStore) !==
            JSON.stringify(this.modalContent3)
          ) {
            this.modalContent3.push(element);
          }
        });
      }
    },
    listCategory() {
      this.isDataCategory = true;
      if (this.isDataAuthor && this.isDataCategory) {
        this.$store.commit("set", ["modalLoadingPage", false]);
      }
      if (this.listCategory.data.length > 0) {
        this.listCategoryStore = this.listCategory.data.map((item) => ({
          text: item.category_name,
          open: false,
          modalId: 2,
          id: item.id,
        }));
        this.listCategoryStore.forEach((element) => {
          if (
            JSON.stringify(this.listCategoryStore) !==
            JSON.stringify(this.modalContent2)
          ) {
            this.modalContent2.push(element);
          }
        });
      }
    },
  },
  async created() {
    this.$store.commit("set", ["modalLoadingPage", true]);
    if (this.$route.query.keyword || this.$route.query.categoryId) {
      if (this.$route.query.keyword) {
        this.formdata.keyword = this.$route.query.keyword;
        this.keywordDisplay.push(this.formdata.keyword);
      }
      if (this.$route.query.categoryId) {
        const categoryId = Number(this.$route.query.categoryId);
        const data = [categoryId];
        this.formdata.category_id = data;
        this.keywordDisplay.push(this.formdata.category_id);
      }

      this.isDisplayRecommend = false;
      this.getDataStore(this.formdata);
    } else {
      this.getListRecommendInStore(this.formRecommend);
    }
    this.getListAuthorInStore(this.formAuthor);
    this.getListCategoryInStore(this.formCategory);
  },
  methods: {
    ...mapActions({
      getDataStore: "getDataStore",
      addToCart: "addToCart",
      getListCart: "getListCart",
      getListAuthorInStore: "getListAuthorInStore",
      getListCategoryInStore: "getListCategoryInStore",
      getListRecommendInStore: "getListRecommendInStore",
    }),
    moment,
    formatNumber(value) {
      return Number(value).toLocaleString("ja");
    },
    async addCart(value) {
      this.checkData = value.id;
      this.idContentChangeCart = value.id;
      if (value.sale_other && value.sale_other.url_item_click_redirect) {
        window.location.href = value.sale_other.url_item_click_redirect;
      } else {
        const shop_id = this.shop_id;
        const formdata = {
          content_id: [value.id.toString()],
          shop_id: shop_id,
          title: this.$route.meta.title,
          referrer_url: window.location.href,
          url: window.document.activeElement.baseURI,
          id_button: "add_content_" + value.id,
          add_cart_url:
            window.location.origin +
            this.$router.resolve({
              name: "content detail",
              params: {
                shopId: this.shop_id,
                id: value.id,
              },
              query: { keyword: "" },
            }).href,
        };
        const dataReturn = await this.$store.dispatch("addToCart", formdata);
        if (dataReturn) {
          if (dataReturn.success) {
            const checkCart = await this.$store.dispatch("getListCart", {
              shop_id,
            });
            if (checkCart) {
              this.checkData = null;
            }
            this.checkData = null;
          }
          this.checkData = null;
        }
      }
    },
    showHideModal() {
      this.isOpenFilter = !this.isOpenFilter;
      if (this.isOpenFilter) {
        this.modalContent = this.modalContent1;
        this.modalTitle = "絞り込み検索";
      }
      this.modalOpen = 1;
      this.loadSearch = false;
    },
    returnModal() {
      this.modalOpen = 1;
      this.modalContent = this.modalContent1;
      this.modalTitle = "絞り込み検索";
    },
    chooseFilter(value) {
      if (value.open) {
        this.modalContent = this["modalContent" + value.openId];
        this.modalOpen = value.openId;
        this.modalTitle = value.text;
      } else {
        if (this.modalOpen === 2) {
          if (this.categorySelect.find((item) => item.id === value.id)) {
            this.formdata.category_id.splice(
              this.formdata.category_id.indexOf(value.id),
              1
            );
            this.categorySelect.splice(this.categorySelect.indexOf(value), 1);
          } else {
            this.formdata.category_id.push(value.id);
            this.categorySelect.push(value);
          }
        } else if (this.modalOpen === 3) {
          if (this.authorSelect.find((item) => item.id === value.id)) {
            this.formdata.author_id.splice(
              this.formdata.author_id.indexOf(value.id),
              1
            );
            this.authorSelect.splice(this.authorSelect.indexOf(value), 1);
          } else {
            this.formdata.author_id.push(value.id);
            this.authorSelect.push(value);
          }
        } else {
          if (this.filterSelect.find((item) => item.id === value.id)) {
            this.formdata.content_type.splice(
              this.formdata.content_type.indexOf(value.id),
              1
            );
            this.filterSelect.splice(this.filterSelect.indexOf(value), 1);
          } else {
            this.formdata.content_type.push(value.id);
            this.filterSelect.push(value);
          }
        }
      }
    },
    searchList(value) {
      this.category_name = value.category.category_name;
      if (value.category.category_name === "recommend") {
        this.moreRecommend = true;
      } else {
        this.categoryDisplay = [];
        const form = {
          text: value.category.category_name,
          open: false,
          modalId: 2,
          id: value.category.category_id,
        };

        this.categorySelect.push(form);
        this.categorySelect.forEach((element) => {
          this.categoryDisplay.push(element);
        });

        this.formdata.category_id.push(value.category.category_id);
      }
      this.getDataStore(this.formdata);
      this.isDisplayRecommend = false;
      this.isSeeMore = false;
    },
    removeFilter(index, modalId) {
      if (modalId === 1) {
        this.filterSelect.splice(index, 1);
        this.filterDisplay.splice(index, 1);
        this.formdata.content_type.splice(index, 1);
      } else if (modalId === 2) {
        this.categorySelect.splice(index, 1);
        this.categoryDisplay.splice(index, 1);
        this.formdata.category_id.splice(index, 1);
      } else if (modalId === 3) {
        this.authorSelect.splice(index, 1);
        this.authorDisplay.splice(index, 1);
        this.formdata.author_id.splice(index, 1);
      } else {
        this.keywordDisplay.splice(index, 1);
        this.formdata.keyword = "";
      }
      if (
        this.filterDisplay.length > 0 ||
        this.categoryDisplay.length > 0 ||
        this.authorDisplay.length > 0 ||
        this.keywordDisplay.length > 0
      ) {
        this.isDisplayRecommend = false;
      } else {
        this.isDisplayRecommend = true;
      }
      this.moreRecommend = false;
      this.messageEmptyContent = "";
      this.messageEmpty = "";
      this.getDataStore(this.formdata);
      this.getListRecommendInStore(this.formRecommend);
    },
    async searchStore() {
      this.loadSearch = true;
      this.filterDisplay = [];
      this.authorDisplay = [];
      this.categoryDisplay = [];
      this.keywordDisplay = [];
      this.filterSelect.forEach((element) => {
        this.filterDisplay.push(element);
      });
      this.authorSelect.forEach((element) => {
        this.authorDisplay.push(element);
      });
      this.categorySelect.forEach((element) => {
        this.categoryDisplay.push(element);
      });
      if (this.formdata.keyword) {
        this.keywordDisplay.push(this.formdata.keyword);
      }
      this.formdata.page = 1;
      await this.getDataStore(this.formdata);
      this.isDisplayRecommend = false;
      this.isSeeMore = false;
      this.modalOpen = 1;
      this.showHideModal();
    },
    seeMore() {
      this.formdata.page += 1;
      this.isSeeMore = true;
      this.getDataStore(this.formdata);
    },
    seeMoreCategory() {
      this.formCategory.page += 1;
      this.getListCategoryInStore(this.formCategory);
    },
    seeMoreAuthor() {
      this.formAuthor.page += 1;
      this.getListAuthorInStore(this.formCategory);
    },
    goToDetailAuthor(author_id) {
      this.$router.push({
        name: this.$route.params.shopId
          ? "author detail"
          : "author detail domain",
        params: {
          shopId: this.shop_id,
          id: author_id,
        },
      });
    },
    goToDetailContent(value) {
      if (
        this.user_type == Constants.USER_TYPE_DEFAULT["user_normal"] &&
        value.sale_other &&
        value.sale_other.url_item_click_redirect &&
        value.sale_advance &&
        value.sale_advance.checkbox_url_redirect === 1
      ) {
        window.location.href = value.sale_other.url_item_click_redirect;
      } else {
        this.$router.push({
          name: this.$route.params.shopId
            ? "content detail"
            : "content detail domain",
          params: {
            shopId: this.$route.params.shopId,
            id: value.id,
          },
          query: { keyword: this.formdata.keyword },
        });
      }
    },
    check(id) {
      const listCheck = this.listCart;
      var check = false;
      listCheck.filter(function (ele) {
        if (id == ele.content_id) {
          check = true;
          return;
        }
      });
      return check;
    },
    checkloading(index) {
      if (this.checkData == index) return true;
      else return false;
    },
    onWheel() {
      const carouselEls = document.getElementsByClassName("car-slide");
      for (let i = 0; i < carouselEls.length; i++) {
        carouselEls[i].addEventListener("wheel", (e) => {
          const refName = "carousel" + i;
          if (e.deltaX) e.preventDefault();
          if (e.deltaX > 15) {
            this.$refs[refName][0].next();
          } else if (e.deltaX < -15) {
            this.$refs[refName][0].prev();
          }
        });
      }
    },
    createRef(index) {
      let number = index;
      if (this.settingWidget == 1) number = Math.floor(index / 4);
      return "carousel" + number;
    },
  },
};
</script>

<style lang="scss" scoped>
.ct-home {
  &-tabs {
    height: 100px;
    background: #d5d5d5;
  }
  &-search {
    position: relative;
    width: 400px;
    height: 100%;
    input {
      width: 100%;
    }
    .search {
      &-input {
        padding-right: 35px;
      }
      &-icon {
        position: absolute;
        top: 50%;
        right: 8px;
        transform: translateY(-50%);
        cursor: pointer;
      }
    }
  }
  &-tab {
    .custom-tab {
      width: 120px;
      height: 60px;
      border-radius: 10px 10px 0 0;
      &.active {
        background: #ebedef;
      }
    }
  }
  &-filter {
    padding: 30px 15px;
    .filter-btn {
      button {
        padding: 10px 20px;
        border-radius: 10px;
        @media (max-width: 767px) {
          padding: 0 10px;
        }
      }
    }
    .filter-icon {
      font-size: 24px;
    }
  }
  &-link {
    a {
      font-size: 18px;
      color: #000;
      text-decoration: underline;
      @media (max-width: 767px) {
        font-size: 12px;
        margin: 0 10px !important;
      }
    }
  }
  &-allOption {
    // height: 98px;
    .allOption {
      &-close {
        width: 25px;
        height: 25px;
        background: rgb(110, 110, 110);
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        cursor: pointer;
      }
    }
  }
  &-modalFilter {
    .modalFilter {
      &-backdrop {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(5px);
        background-color: #0000008f;
        z-index: 10;
      }
      &-content {
        top: 50%;
        left: 50%;
        width: 702px;
        max-width: calc(100% - 20px);
        margin: auto;
        transform: translate(-50%, -50%);
        background: #fff;
        z-index: 11;
        border: 1px solid #b5b5b5;
        border-radius: 10px;
        @media (max-width: 767px) {
          top: 40%;
        }
      }
      &-header {
        padding: 25px 0;
        border-bottom: 1px solid #b5b5b5;
        font-size: 20px;
      }
      &-item {
        padding: 25px 20px;
        width: 700px;
        max-width: 100%;
        cursor: pointer;
        &:hover {
          background: #e3e3e3;
        }
      }
      &-search {
        padding: 20px;
        padding-top: 0;
        .ct-home-search {
          width: 100%;
          input {
            height: 50px;
          }
          .search-icon {
            right: 10px;
            font-size: 20px;
          }
        }
      }
      &-close {
        position: absolute;
        top: 15px;
        right: 15px;
        font-size: 25px;
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      &-return {
        position: absolute;
        top: 15px;
        left: 15px;
        font-size: 25px;
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      &-itemIcon {
        top: 50%;
        right: 25px;
        transform: translateY(-50%);
        font-size: 20px;
      }
      &-check {
        color: #46c000;
      }
      &-btn {
        padding: 0 20px;
        button {
          width: calc((100% - 20px) / 2);
          height: 40px;
        }
      }
      &-success {
        color: #fff;
        background: #d3421e;
        border: unset;
      }
    }
  }
  &-card {
    @media (min-width: 768px) {
      border-bottom: 1px solid #e6e6e6 !important;
    }
    a:hover {
      text-decoration: none;
    }
    width: 100%;
    .card {
      &-img {
        // background-color: #dee9f0;
        //background: rgb(3, 118, 187);
        height: 180px;
        width: 100%;
        &-widget {
          // height: 250px;
          // width: 250px;
          // background-color: #dee9f0;
          //background: rgb(3, 118, 187);

          //height: 200px;
        }
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
        //@media (max-width: 767px) {
        //  width: 130px;
        //  height: 130px;
        //}
      }
      &-avatar {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        overflow: hidden;
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
      &-btn {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.ct-home-card {
  font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", Avenir,
    "Open Sans", "Helvetica Neue", Helvetica, Arial, Verdana, Roboto,
    "游ゴシック", "Yu Gothic", "游ゴシック体", YuGothic, "Meiryo UI", "メイリオ",
    Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  background-color: white;
}

.ct-home-card:hover {
  @media (min-width: 768px) {
    cursor: pointer;
    box-shadow: 0 5px 5px #888888;
  }
}
.slick-next {
  right: -50px;
  z-index: 1;
  @media (max-width: 549px) {
    right: -30px;
  }
}
.slick-prev {
  left: -50px;
  z-index: 1;
  @media (max-width: 549px) {
    left: -30px;
  }
}
.slick-next::before {
  color: black !important;
}
.slick-prev::before {
  color: black !important;
}
.slick-slide {
  margin: 0 26px;
}
.slick-list {
  margin: 0 -26px;
}
.btn-width {
  min-width: 150px;
}
.widget1 {
  //display: flex;
  justify-content: center;
  position: relative;
  border: unset !important;
  //@media (max-width: 991px) {
  //  align-items: center;
  //}
  //@media (max-width: 767px) {
  //  left: -100px;
  //}
  //@media (max-width: 549px) {
  //  left: -60px;
  //}
  img {
    height: 100%;
    width: 100%;
  }

  .info-content {
    @media (max-width: 767px) {
      display: none;
    }
  }
}
.widget2 {
  position: relative;
  // left: 50%;
  border: unset !important;
  img {
    height: 100%;
    width: 100%;
  }
  .card-img-widget {
    @media (max-width: 549px) {
      width: 130px;
      height: 130px;
    }
  }
  @media (max-width: 549px) {
    padding: 20px 10px;
  }
  .info-content {
    .font-title-content {
      @media (max-width: 549px) {
        font-size: 10px;
      }
    }
    div {
      // padding-right: 0 !important;
      // padding-left: 0 !important;
      .card-avatar {
        @media (max-width: 549px) {
          width: 20px;
          height: 20px;
        }
      }
      .btn-width {
        @media (max-width: 549px) {
          min-width: 120px;
        }
      }
    }
  }
}
.title-store {
  -webkit-line-clamp: 9;
  // display: -webkit-box !important;
  -webkit-box-orient: vertical;
  overflow: hidden;
  // color: #fff !important;
  //white-space: nowrap;
  //width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}
.img-widget {
  width: 250px !important;
  height: 250px !important;
  @media (max-width: 767px) {
    width: 150px !important;
    height: 150px !important;
  }
  @media (max-width: 549px) {
    width: 100px !important;
    height: 100px !important;
  }
}
.title-widget {
  @media (max-width: 767px) {
    font-size: 11px;
  }
  @media (max-width: 549px) {
    font-size: 9px;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
.carousel-widget {
  width: 80%;
  margin: 0 auto;
}
.content-widget {
  justify-content: center;
  div {
    .ct-home-card {
      left: 0;
      border: unset !important;
      .card-img-widget {
        width: 400px;
        height: 400px;
        // background: rgb(3, 118, 187) !important;
        @media (max-width: 767px) {
          width: 300px;
          height: 300px;
        }
        @media (max-width: 549px) {
          width: 200px;
          height: 200px;
        }
      }
    }
  }
}
.content-filter {
  @media (max-width: 767px) {
    margin: 0 auto;
  }
}
.card-btn {
  width: 50%;

  .text {
    color: red;
    height: 28px;
    display: flex;
    align-items: center;
  }
  .btn-add-cart {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: #ffd831 !important;
    border-color: #ffd831 !important;
  }
}
.image-advertising {
  img {
    width: 100%;
    height: 100px;
    object-fit: cover;
  }
}
.widget2 {
  .widget4 {
    // max-width: 250px;
  }
}
.widget4 {
  margin: 0 auto;
  img {
    width: 100%;
    height: 100%;
  }
}
.info-content {
  border-bottom: 1px solid #e6e6e6;
}
.content-cart-sp {
  box-shadow: 0px 3px 6px #7681929c;
  width: 100%;
  height: auto;
  border-radius: 10px;
  padding: 20px;
  .info {
    align-items: center;
    .image {
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgb(222, 233, 240);
      height: 100px;
      min-width: 100px;
      position: relative;
      img {
        height: 100%;
        width: 100%;
      }
      .icon-favorite {
        position: absolute;
        left: 0;
        top: 0;
        color: red;
        height: 1rem;
      }
    }
    .info-content {
      .author {
        .card-avatar {
          width: 40px;
          height: 40px;
          border-radius: 100%;
          overflow: hidden;
          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
.page-store {
  .card-img {
    background: #0376bb;
    border-radius: inherit;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.3s;
    aspect-ratio: 1/1;
  }
  .card-price {
    width: 50%;
    font-size: 1.65rem !important;
    border: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .card-name {
    overflow: hidden;
    font-size: 12px;
    line-height: 16px;
    height: 48px;
    margin: 10px 5px 5px;
    padding: 0;
  }

  .see-more {
    text-align: center;
    padding: 10px 2% 5%;
  }
}
.card-desc {
  display: block;
  overflow: hidden;
  font-size: 14px;
  line-height: 16px;
  height: 16px;
  text-align: right;
  margin: 0 5px 5px;
  padding: 0;
}

@media (max-width: 450px) {
  /* .card-desc-pc {
    display: none !important;
  }

  .card-desc-sp {
    display: block;
    overflow: hidden;
    font-size: 12px;
    line-height: 16px;
    height: 16px;
    text-align: right;
    margin: 0 5px 5px;
    padding: 0;
  }

  .see-more button {
    width: 100% !important;
  } */

  .card-desc {
    font-size: 12px !important;
  }

  .category-name {
    display: block;
    font-family: "Noto Sans JP";
    font-weight: 700;
    font-size: 140%;
    text-align: center;
    margin: 10px 0 20px;
  }
}

@media (min-width: 450px) {
  .category-name,
  .card-desc-sp {
    display: none;
  }
}
</style>
